.header-my-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 34px;
    height: 62px;
    background-color: #fff;
    box-shadow: 0 4px 3px -2px grey;
}

.group-action-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.group-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0078BF;
    border-radius: 19px;
    height: 38px;
    width: 180px;
}

.text-logout {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.icon-header {
    margin-right: 20px;
    cursor: pointer;
}

.select-focus {
    font-family: 'Noto Sans Regular';
    font-weight: 400;
    font-size: 16px;
    color: #2B2B2B;
}