.form-group label {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 10px;
}

.container-update-company, .container-add-issue {
    width: 75%;
    background-color: #f1f2f6;
    padding: 60px 30px;
}

.container-update-company label {
    padding: unset;
}

.avatar-block {
    width: 168px;
    height: 225px;
    border: 1px solid #B3B3B3;
    border-radius: 12px;
    background: #F4F4F4 url("/src/assets/image/add_avatar.svg") no-repeat center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-bottom: 30px;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 10px;
}

.preview-avatar {
    min-width: 168px;
    width: 168px;
    height: 225px;
    border: 1px solid #B3B3B3;
    border-radius: 12px;
    padding: unset;
    cursor: pointer;
    margin-bottom: 10px;
}

.des-avatar {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.title-form-update-company {
    font-family: "Epilogue";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0078BF;
    margin-right: 20px;
}

.info-form-update-company {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #555555;
}

.body-form-update-company {
    margin: unset;
    justify-content: center;
}

.row {
    margin: unset;
    padding: unset;
}

.text-area-company {
    background: #FFFFFF;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    height: 195px;
    resize: none;
    font-family: "Noto Sans Regular";
    font-size: 16px;
}

.select-company {
    background: #FFFFFF;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    height: 38px;
    width: 190px;
    margin-right: 20px;
    margin-bottom: 10px;
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-size: 16px;
    color: #B3B3B3;
    cursor: pointer;
}

.text-error-custom {
    width: 190px;
}

.select-company:last-child {
    margin-right: unset;
}

.color-placeholder {
    color: #000000 !important;
}

select:invalid, select option[value=""] {
    color: #999999;
}

option:first-child {
    color: #ccc;
}

.form-control {
    border: 1px solid #B3B3B3;
}

.btn-save-company {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
}

.input-date-picker {
    width: 190px;
    margin-right: 20px;
}

.checkbox-block {
    width: 70%;
}

.group-date-establishment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px !important;
}

.checkbox-block label.main-text {
    margin: 3px 0 0 0;
    cursor: pointer;
}

@media (max-width: 546px) {
    .checkbox-block {
        width: 100%;
    }

    .parent-btn-save-company {
        width: 100%;
        display: flex;
        justify-content: end;
    }
}

@media (max-width: 1100px) {
    .container-update-company .row .col-lg-12 {
        padding: unset;
    }

    .body-update-company {
        display: flex;
        flex-direction: column;
    }

    .group-date-establishment {
        display: flex;
        flex-direction: column;
    }

    .text-error-custom {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .container-update-company {
        padding: 50px 30px !important;
    }
}

@media (max-width: 475px) {
    .select-company {
        width: 100%;
    }
}

@media (max-width: 546px) {
    .container-update-company {
        width: 100%;
        padding: 50px !important;
    }
}

.banner-my-page {
    height: 194px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f2f6;
    padding: 30px 200px;
}

.group-text-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-for-company {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #2B2B2B;
    width: 100%;
    text-align: center;
}

.list-coach {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #2B2B2B;
    margin-bottom: 20px;
}

.search-coach-block {
    background-color: #fff;
}

.btn-feature {
    width: 232px;
    height: 62px;
    background: #FFFFFF;
    border: 1px solid #0078BF;
    border-radius: 31px;
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: start;
    color: #0078BF;
    padding: 0 15px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.btn-feature.active {
    background: #0078BF;
    color: #FFFFFF;
}

.group-action-company {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 30px 200px;
}

.group-btn-second {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 70%;
}

.btn-mission {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-search-keyword {
    height: 38px;
    left: 720px;
    top: 530px;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    padding: 0 2rem 0 0.75rem;
    width: 304px;
    min-width: 200px;
    background: #FFFFFF url("/src/assets/image/search_icon.svg") no-repeat;
    background-position-y: center;
    background-position-x: 95%;
}

.input-search-keyword::placeholder {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #B3B3B3;
}

.select-search {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #B3B3B3;
    padding: 0.375rem 0.75rem;
    margin: unset;
}

.btn-search {
    width: 84px;
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.condition-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 125px;
    column-gap: 10px;
}

.title-search {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #2B2B2B;
    min-width: 90px;
}

.select-type {
    width: 231px;
    min-width: 147px;
}

.select-location {
    width: 185px;
    min-width: 147px;
}

.result-search {
    padding: 20px 100px;
}

.title-result {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    text-transform: uppercase;
    color: #0078BF;
    margin-right: 20px;
    padding-left: 18px;
}

.list-result {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #555555;
}

.result-item {
    border-radius: 10px;
    border: 3px solid #0078BF;
    width: 30%;
    height: 407px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 260px;
    position: relative;
    margin: 0 18px 36px 18px;
}

.result-item-link {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 254px;
    position: relative;
}

.favorite-button {
    position: absolute;
    top: 50%;
    left: 85%;
}


.list-result-search {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.title-item {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.detail-item {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.skills-overview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.group-des-item {
    display: flex;
    align-items: start;
    flex-direction: column;
    background: #F1F2F6;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    height: 154px;
    min-height: 154px;
}

.group-avatar-bg {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.img-favorite {
    width: 31px;
}

@media (max-width: 1440px) {
    .condition-search {
        padding: 20px 100px;
    }
}

@media (max-width: 1400px) {
    .group-action-company {
        padding: 30px 150px;
    }
}

@media (max-width: 1368px) {
    .result-search {
        padding: 20px 70px;
    }

    .result-item {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 1024px) {
    .condition-search {
        flex-direction: column;
        align-items: start;
    }

    .title-search {
        margin-bottom: 20px;
    }

    .select-search, .input-search-keyword {
        margin-bottom: 20px;
    }

    .select-type, .select-location {
        width: 40%;
    }

    .input-search-keyword {
        width: 60%;
    }
}

@media (max-width: 992px) {
    .group-action-company {
        padding: 20px 100px;
    }

    .banner-my-page {
        padding: 30px 100px;
    }

    .list-coach {
        font-size: 24px;
    }

    .btn-for-company {
        font-size: 16px;
        width: 120px;
    }

    .header-add-issue {
        flex-direction: column;
        align-items: start !important;
    }
}


@media (max-width: 900px) {
    .type-issue-block {
        flex-direction: column;
        align-items: start !important;
    }

    .remote-face {
        margin-bottom: 20px;
    }

    .free-paid-block {
        width: 80% !important;
    }
}

@media (max-width: 768px) {
    .result-item {
        width: 310px;
    }

    .banner-my-page {
        padding: 30px 50px;
    }

    .group-action-company {
        flex-direction: column;
        justify-content: space-between;
    }
    .group-btn-second {
        width: 100%;
        justify-content: start;
    }
}

@media (max-width: 750px) {
    .result-item {
        width: 260px;
    }

    .container-add-issue {
        padding: 50px !important;
    }
}

@media (max-width: 700px) {
    .result-item {
        width: 230px;
    }
}

@media (max-width: 640px) {
    .list-result-search {
        justify-content: center;
    }

    .result-search {
        padding: 20px 30px;
    }

    .result-item {
        width: 308px;
    }
}

@media (max-width: 540px) {
    .condition-search {
        padding: 20px 50px;
    }

    .banner-my-page img {
        width: 100px;
    }

    .header-my-page {
        padding: 0 10px !important;
    }
}

@media (max-width: 450px) {
    .btn-feature {
        width: 200px;
        min-width: 200px;
    }

    .banner-my-page {
        padding: 30px 10px;
    }

    .banner-my-page .list-coach {
        font-size: 20px;
    }

    .banner-my-page .btn-for-company {
        font-size: 14px;
        width: 100px;
    }

    .group-logout {
        width: 120px !important;
    }

    .icon-header {
        margin-right: 10px !important;
    }
}

@media (max-width: 390px) {
    .group-action-company {
        padding: 20px 70px;
    }
}

.type-student-tag {
    text-overflow: ellipsis;
    max-width: 200px;
    min-width: 92px;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px 10px;
    text-align: center;
    height: 32px;
    background: #FFFFFF;
    border: 1.5px solid #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #0078BF;
    position: absolute;
    top: 20px;
    left: 15px;
}

@media (max-width: 1399px) {
    .title-result {
        padding-left: 14px;
    }

    .result-item {
        margin: 0 14px 36px 14px;
    }

    .form-content-block {
        width: 90% !important;
    }
}

@media (max-width: 1199px) {
    .header-content-issue {
        margin-bottom: 20px !important;
    }

    .text-info-group {
        margin-bottom: 20px !important;
    }

    .text-detail-issue {
        margin-bottom: unset !important;
    }

    .result-search {
        padding: 20px 40px;
    }

    .result-item {
        margin: 0 10px 36px 10px;
    }

    .form-content-block {
        width: 80% !important;
    }

    .item-issue {
        width: 230px !important;
    }

    .content-issue-left {
        width: 100% !important;
        margin-bottom: 40px;
    }

    .title-issue {
        line-height: 50px !important;
    }

    .btn-save-issue {
        margin-bottom: unset !important;
    }

    .content-issue {
        padding-bottom: 100px;
    }

    .btn-issue-custom {
        bottom: 15px !important;
    }
}

@media (max-width: 991px) {
    .result-search {
        padding: 20px 40px;
    }

    .item-issue {
        width: 450px !important;
        height: 300px !important;
        margin-bottom: 20px;
    }
}

@media (max-width: 943px) {
    .result-search {
        padding: 20px 90px;
    }

    .result-item {
        width: 45%;
    }
}

@media (max-width: 767px) {
    .container-app {
        width: 100% !important;
        margin: unset !important;
        max-width: 100%;
        padding: unset;
    }
}

@media (max-width: 739px) {
    .result-search {
        padding: 20px 90px;
    }

    .result-item {
        width: 50%;
    }

    .list-result-search {
        justify-content: center;
    }
}

@media (max-width: 695px) {
    .result-search {
        padding: 20px 55px;
    }
}

@media (max-width: 670px) {
    .result-search {
        padding: 20px 100px;
    }

    .title-result {
        padding-left: unset;
    }

    .result-item {
        width: 308px;
    }

    .list-result-search {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .item-issue {
        width: 320px !important;
        height: 225px !important;
    }
}

@media (max-width: 540px) {
    .result-search {
        padding: 20px 50px;
    }
}

@media (max-width: 514px) {
    .body-issue {
        flex-direction: column-reverse;
        align-items: center;
    }
    .back-group {
        width: 100%;
        margin-top: 20px;
        flex-direction: unset !important;
        max-width: unset !important;
    }

    .back-group img {
        margin-right: 10px;
    }

    .form-content-block {
        border-bottom: 2px solid #0078BF;
        padding-bottom: 27px;
        border-left: unset !important;
        padding-left: unset !important;
    }

    .content-issue {
        padding-bottom: 50px;
    }

    .btn-issue-custom {
        bottom: -10px !important;
    }
}

@media (max-width: 375px) {
    .info-company-block {
        width: 290px !important;
    }

    .header-issue-right {
        flex-direction: column;
        align-items: start !important;
    }
}

.container-add-issue {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 100px 300px;
}

.header-add-issue {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 71px;
}

.title-add-issue {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0078BF;
    margin-right: 20px;
}

.des-add-issue {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #555555;
}

.image-add-issue-block {
    width: 100%;
    height: 225px;
    background: #F4F4F4;
    border: 1px solid #B3B3B3;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;
}

.image-add-issue-block img {
    margin-right: 10px;
}

.remote-face {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.type-issue-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}

.free-paid-block {
    width: 50%;
    height: 32px;
    left: 599px;
    top: 466px;
    background: #EEEEEE;
    border-radius: 9px;
    padding: 2px;
}

.btn-free-paid {
    background: #EEEEEE;
    border-radius: 7px;
    height: 28px;
    width: 50%;
    font-family: 'Hiragino Kaku Gothic Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #424242;
}

.btn-free-paid:hover {
    opacity: unset;
}

.btn-free-paid.active {
    background: #0078BF;
    font-family: 'Hiragino Kaku Gothic Pro';
    font-weight: 600;
    color: #fff;
}

.custom-checkbox-issue label:before {
    top: unset;
    bottom: -7px;
}

.custom-checkbox-issue input:checked + label:after {
    top: unset;
    bottom: -1px;
}

.custom-checkbox-issue {
    margin-right: 6px;
}

.remote-face label {
    cursor: pointer;
}

.container-add-issue label {
    padding: unset;
}

.remote-face label.main-text {
    padding-left: 25px;
}

.form-group {
    margin-bottom: 14px;
}

.form-group-issue {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 14px;
}

.select-company {
    padding: 0 10px;
}

.fake-col {
    width: 190px;
}

@media (max-width: 1599px) {
    .container-add-issue {
        padding: 100px 150px;
    }
}

@media (max-width: 1260px) {
    .container-add-issue {
        padding: 100px;
    }
}

@media (max-width: 600px) {
    .container-add-issue {
        width: 100%;
    }
}

.group-btn-issue {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
}

.btn-save-issue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.btn-issue-custom {
    position: absolute;
    bottom: 0;
}

.btn-issue-back {
    left: 150px;
}

.preview-image {
    height: 100%;
}

.body-issue {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    background: #f1f2f6;
    width: 100%;
    padding: 71px 0;
}

.back-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    min-width: 100px;
    background: #F1F2F6;
}

.form-content-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 85%;
    border-left: 2px solid #0078BF;
    padding-left: 27px;
}

.header-content-issue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 71px;
}

.item-issue {
    width: 320px;
    height: 225px;
    left: 127px;
    top: 133px;
    background: #F4F4F4;
    border: 1px solid #B3B3B3;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-issue img {
    height: 100%;
    max-width: 80%;
}

.custom-default-size {
    width: 40%;
}

.content-issue {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    position: relative;
}

.content-issue-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 65%;
}

.text-info-group {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 51px;
}

.text-info-issue {
    min-height: 32px;
    background: #EEEEEE;
    border: 1px solid #757575;
    border-radius: 8px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-family: 'Hiragino Kaku Gothic Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #424242;
    word-break: break-word;
}

.title-issue {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 32px;
    color: #2B2B2B;
    margin-bottom: 17px;
}

.company-name {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    color: #555555;
    margin-bottom: 24px;
}

.title-detail-issue {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.text-detail-issue {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #555555;
    margin-bottom: 71px;
    padding-right: 10px;
}

.content-issue-right {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.header-issue-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.quantity-left {
    background: #FFFFFF;
    border: 1px solid #0078BF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0078BF;
    height: 44px;
    padding: 8px 12px;
    flex-direction: column;
    margin-right: 20px;
}

.date-register {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    color: #555555;
}

.info-company-block {
    width: 320px;
    border: 1px solid #0078BF;
    border-radius: 12px;
    height: 436px;
    padding: 20px;
}

.text-back {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.text-info-company {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 3px;
    color: #2B2B2B;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
}

.text-info-company p {
    font-size: 16px;
    font-family: 'Noto Sans Regular';
    margin-bottom: 15px;
    line-height: 22px;
    word-break: break-word;
}

.des-image-add {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
}

.pl-unset {
    padding-left: unset !important;
}

.type-issue {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #0078BF;
}

.quantity-student-apply {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 32px;
    color: #DC3D3D;
}

.title-issue-custom {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #FFFFFF !important;
}

.title-issue-list {
    font-size: 12px !important;
    line-height: 32px !important;
    color: #FFFFFF !important;
}

.group-tag-issue {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.btn-tag-issue {
    border-radius: 19px;
    border: 2px solid #0078BF;
    background: #FFF;
    padding: 0 15px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0078BF;
    font-size: 12px;
    font-family: "Noto Sans Bold";
    margin-right: 10px;
}

.btn-tag-issue:hover {
    opacity: 1;
}

.group-date-issue {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.expired-date {
    color: #DC3D3D;
    font-family: "Noto Sans Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3px;
}
