.form-login {
    height: 430px !important;
}

.group-login {
    min-height: 195px;
}

.forgot-password-block {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}