.admin-container {
    display: grid;
    grid-template-columns: 300px 1fr;
    width: 100%;
}

.admin-header {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 3px -2px grey;
    display: flex;
    height: 62px;
    justify-content: flex-end;
    margin-bottom: 3px;
    padding: 0 20px;
    width: 100%;
}

.avatar-admin {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #9ca3af;
}

.name-admin {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
    color: #fff;
    font-size: 20px;
}

.admin-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 62px;
    padding: 10px;
}

.admin-sidebar {
    width: 300px;
    background: #1c2536;
    border-right: 1px solid #9ca3af;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
}

.text-admin {
    font-family: "Noto Sans Bold";
    font-size: 20px;
    color: #fff;
    padding: 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 62px;
}

.admin-sidebar__items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    height: 45px;
    cursor: pointer;
}

.admin-sidebar__items.active {
    background: #fff;
}

.admin-sidebar__items:not(.active):hover {
    background: #26303e;
}

.admin-sidebar__items.child-items {
    padding-left: 30px;
}

.admin-sidebar__items img {
    margin-right: 20px;
    width: 25px;
    height: 25px;
}

.admin-sidebar__items .name {
    font-family: "Noto Sans Bold";
    font-size: 16px;
    color: #979ea9;
}

.content-admin-group {
    display: grid;
    grid-template-rows: 62px auto;
    min-height: 100vh;
}

.manager-block {
    min-height: calc(100vh - 62px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-manager {
    font-family: 'Noto Sans Bold';
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 3px;
    color: #0078BF;
    margin-bottom: 30px;
}

.table-manager th {
    font-family: 'Noto Sans Bold';
    font-size: 16px;
    background: #0078BF;
    color: #fff;
    text-align: center;
}

.table-manager td {
    font-family: 'Noto Sans Regular';
    font-size: 16px;
    color: #555555;
    text-align: center;
    padding: 10px;
    word-break: break-word;
}

.btn-action-manager {
    height: 30px;
    border-radius: 10px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    font-family: "Noto Sans Bold";
    font-size: 16px;
}

.btn-edit-manager {
    background: #0078BF;
    color: #fff;
    margin-right: 20px;
}

.btn-delete-manager {
    background: #dc3545;
    color: #fff;
}

.btn-choose-coach {
    background: #15b45c;
    color: #fff;
}

td.no-data {
    color: #D32929;
}

.btn-register-coach {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 38px;
    background: #28a745;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
}

.modal-backdrop {
    z-index: 1055 !important;
}

.sub-modal {
    z-index: 1060 !important;
}

.user-info-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.user-info-group span {
    font-family: "Noto Sans Bold";
    font-size: 16px;
    color: #555555;
}

.user-info-group div {
    font-family: "Noto Sans Regular";
    font-size: 16px;
    color: #555555;
    word-break: break-word;
}

.modal-info-user .modal-dialog-centered {
    min-width: 700px;
}

.modal-list-user .modal-dialog-centered {
    min-width: 700px;
}

.view-profile {
    font-family: 'Noto Sans Regular';
    font-size: 16px;
    color: #0078BF;
    cursor: pointer;
}

.view-profile:hover {
    text-decoration: underline;
}

.btn-switch-choose-coach {
    height: 30px;
    border-radius: 10px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0078BF;
    color: #fff;
}

.name-create-data {
    margin-right: 10px;
    width: 50px;
    font-family: "Noto Sans Bold";
    font-size: 16px;
}

.input-quantity {
    width: 100px;
    text-align: center;
}

.btn-disabled {
    background-color: #555555;
}