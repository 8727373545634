.group-left-info {
    display: flex;
    flex-direction: column;
}

.back-from-update {
    width: 168px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.body-update-coach {
    display: flex;
}

.mr-avatar {
    margin-bottom: 270px;
}

@media (max-width: 1100px) {
    .mr-avatar {
        margin-bottom: unset;
    }

    .group-left-info {
        flex-direction: unset;
        align-items: center;
    }

    .parent-avatar {
        margin-right: 20px;
    }
}

@media (max-width: 440px) {
    .back-from-update {
        margin-top: unset;
    }

    .group-left-info {
        flex-direction: column;
        align-items: start;
    }

    .back-from-update {
        margin-bottom: 20px;
    }
}