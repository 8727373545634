.slide-container {
    width: 100%;
}

.image-slide {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.each-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}

.description-slide {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    height: 100%;
}

.title-description-slide {
    display: flex;
    justify-content: start;
    align-items: center;
}

.slide-title {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0078BF;
}

.slide-title-second {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #555555;
}

.slide-title-description {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 70px;
    color: #2B2B2B;
}

.content-description-image {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #555555;
}

.btn-see-more {
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
    padding: 0 15px;
}

.image-slide-block {
    min-width: 500px;
    width: 500px;
    height: 100%;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 40px;
}

.react-slideshow-container .nav:first-of-type {
    left: 5px;
}

.react-slideshow-container .nav:last-of-type {
    right: 5px;
}

.group-slide {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    height: 390px;
}

@media (max-width: 1280px) {
    .slide-title-description {
        font-size: 26px;
    }
}

.react-slideshow-container+ul.indicators li {
    background-color: #D8D8D8;
    padding: 0;
    display: table-cell;
    line-height: 1px;
    height: 0;
}

ul.indicators {
    margin-left: 40px;
    display: table !important;
    width: 500px;
    margin-top: 40px !important;
}

.react-slideshow-container+ul.indicators li:has(.each-slideshow-indicator.active) {
    background-color: #0078BF;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    background: none;
}

@media (max-width: 640px) {
    .react-slideshow-container+ul.indicators li {
        display: inline-block;
        position: relative;
        width: 7px;
        height: 7px;
        padding: 5px;
        margin: 0;
        background-color: unset;
    }

    .feature-block ul.indicators {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: unset !important;
        width: 100% !important;
    }

    .react-slideshow-container+ul.indicators li:has(.each-slideshow-indicator.active) {
        background-color: unset;
    }

    .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
        background: #000;
    }

    .slide-title {
        font-size: 20px;
    }

    .slide-title-second {
        font-size: 12px;
    }

    .slide-title-description {
        font-size: 18px !important;
        line-height: 30px !important;
    }

    .content-description-image {
        font-size: 14px;
        line-height: 25px;
    }

    .text-header {
        font-size: 14px;
    }

    .group-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100% !important;
    }

    .feature-block {
        height: 650px;
    }

    .feature-block .image-slide-block {
        height: 200px;
        margin-right: unset;
        width: unset !important;
        margin-bottom: 20px;
    }

    .content-description-image {
        margin-bottom: 20px;
    }

    .feature-block .indicators {
        margin: 10px 0 0 10px !important;
    }

    .text-description-service {
        font-size: 20px !important;
    }
}

@media (max-width: 920px) {
    .feature-block {
        height: 500px !important;
    }

    .slide-title-description {
        font-size: 24px;
        line-height: 44px;
    }

    .group-slide {
        height: 320px;
    }

    .image-slide-block {
        width: 320px !important;
        min-width: 320px !important;
    }

    ul.indicators {
        width: 320px !important;
    }
}

@media (max-width: 450px) {
    .description-slide {
        max-width: 320px;
    }
}

@media (max-width: 390px) {
    .description-slide {
        max-width: 300px;
    }
}

@media (max-width: 400px) {
    .overview-service-block {
        justify-content: center !important;
    }
}
