.group-radio-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
}

.group-radio-modal label {
    cursor: pointer;
}

.group-radio-modal input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.modal-list-student-apply .modal-dialog-centered {
    max-width: 700px;
}

.modal-evaluate {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.modal-evaluate .modal-dialog-centered {
    width: 325px;
}

.modal-evaluate .modal-dialog-centered .modal-content {
    min-height: 613px;
    background: #FFFFFF;
    border: 3px solid #0078BF;
    border-radius: 12px;
}

.modal-evaluate.modal-evaluate-detail .modal-dialog-centered .modal-content {
    min-height: 565px;
}

.evaluate-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-evaluate {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    color: #000000;
    padding: 40px 25px 10px 25px;
    margin-bottom: 20px;
}

.rate-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.vote-rate {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
}

.icon-vote-rate {
    cursor: pointer;
    margin-right: 10px;
}

.text-vote {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: #000000;
}

.title-comment {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #000000;
}

.btn-evaluate-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.parent-title-comment {
    width: 260px;
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
}

.comment-evaluate {
    background: #FFFFFF;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    resize: none;
    width: 260px;
    height: 140px;
    padding: 10px;
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.detail-comment-evaluate {
    overflow-y: auto;
    word-break: break-word;
}

.title-comment-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}