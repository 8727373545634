.message-block {
    background: #f1f2f6;
    padding: 60px 120px;
    width: 100%;
    min-height: calc(100vh - 62px);
}

.group-title-page-msg {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.title-page-message {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #0078BF;
    margin-right: 30px;
}

.title-page-message-sub {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #555555;

}

.chat-message-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(250px, 1fr) 2.5fr;
    min-height: 704px;
    height: 704px;
    background: #fff;
    margin-bottom: 40px;
}

.sidebar-chat {
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: auto;
    box-shadow: 0 0 5px rgba(0,0,0,0.30);
    clip-path: inset(0px -15px 0px 0px);
    z-index: 1;
    background: #fff;
    height: 100%;
}

.body-chat {
    display: grid;
    grid-template-rows: 100px 1fr 100px;
}

.body-chat.body-chat-issue {
    grid-template-rows: 150px 1fr 100px;
}

.message-item {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 12px;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.message-item.active {
    background: #C1DAEB;
    pointer-events: none;
}

.message-item:hover {
    background: #C1DAEB;
}

.group-name-chat.un-read > * {
    font-family: 'Noto Sans Bold';
}

.group-name-avatar {
    display: flex;
    justify-content: start;
    align-items: center;
}

.group-name-chat {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 120px;
}

.name-user-chat {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #000000;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.name-user-chat-sub {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.day-last-msg {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    color: #555555;
    text-align: center;
    min-width: 75px;
    position: absolute;
    top: 9px;
    right: 12px;
}

.avatar-chat-block {
    min-width: 42px;
    width: 42px;
    height: 42px;
    background: #F0F0F0;
    border: 0.5px solid #C8C8C8;
    border-radius: 7px;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-issue-default {
    width: 20px;
    height: 20px;
}

.avatar-chat-sidebar {
    min-width: 42px;
    width: 42px;
    height: 42px;
    background: #F0F0F0;
    border: 0.5px solid #C8C8C8;
    border-radius: 7px;
    margin-right: 14px;
}

.header-body-chat {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.30);
    clip-path: inset(0px 0px -15px 0px);
}

.header-body-chat-issue {
    padding: 15px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 1rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.30);
    clip-path: inset(0px 0px -15px 0px);
}

.name-user-body-chat {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #2B2B2B;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 700px;
}

.name-company-chat {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 700px;
}

.content-chat-block {
    height: 500px;
    display: flex;
    align-items: end;
}

.content-body-chat {
    overflow-y: auto;
    padding: 0 40px 40px 40px;
    max-height: 500px;
    width: 100%;
}

.content-msg-block {
    width: 100%;
    display: flex;
    margin-top: 40px;
}

.content-msg-receiver {
    justify-content: start;
}

.content-msg-send {
    justify-content: flex-end;
}

.msg-receiver {
    width: 70%;
    max-width: 70%;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
}

.avatar-chat-content {
    background: #F0F0F0;
    border-radius: 7px;
    width: 51px;
    min-width: 51px;
    height: 51px;
    margin-bottom: 8px;
}

.group-avatar-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.msg-receiver .tag-is-read {
    background: #0078BF;
    border: 1px solid #0078BF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    color: #FFFFFF;
    padding: 0 10px;
    height: 16px;
}

.msg-send .tag-is-read {
    background: #F1F2F6;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    color: #757575;
    padding: 0 10px;
    height: 16px;
}

.group-name-time {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.group-content-msg {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 85%;
    width: 85%;
}

.name-user-msg {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin-right: 15px;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.time-send {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #B9B9B9;
    text-align: center;
    min-width: 70px;
}

.content-msg {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2B2B2B;
    word-break: break-word;
    max-width: 100%;
}

.msg-send {
    width: 70%;
    max-width: 70%;
    background: #C1DAEB;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
}

.footer-body-chat {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0,0,0,0.30);
    clip-path: inset(-15px 0px 0px 0px);
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
}

.input-msg-chat {
    width: 100%;
    resize: none;
    font-family: "Noto Sans Regular";
    font-size: 16px;
}

input-msg-chat::placeholder {
    font-family: "Noto Sans Regular";
    font-size: 16px;
}

.input-msg-chat:focus {
    outline: none;
    border: none;
}

.send-msg-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-send-message, .btn-back-my-page {
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.btn-back-issue {
    margin-top: 85px;
}

.expand-menu-chat {
    display: none;
    cursor: pointer;
}

.group-date-apply {
    display: flex;
    align-items: center;
    justify-content: start;
}

.btn-list-user-apply {
    height: 24px;
    background: #0078BF;
    border: 1px solid #0078BF;
    border-radius: 8px;
    padding: 0 9px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin-right: 10px;
}

.date-apply {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #0078BF;
    margin-right: 10px;
}

.text-date-apply {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #555555;
}

.group-user-apply {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 35px;
}

.data-application {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 4px;
}

.student-name {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-bottom: 23px;
}

.text-name-apply {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    word-break: break-word;
}

.list-users-apply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    height: 160px;
    width: 95%;
    margin-bottom: 20px;
    row-gap: 5px;
}

.modal-list-user-apply .modal-content {
    max-height: 400px;
    max-width: 325px;
    border: 3px solid #0078BF;
}

.modal-list-user-apply .modal-dialog-centered {
    display: flex;
    justify-content: center;
}

.btn-close-list-user-apply {
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}

.btn-close-list-user-apply:hover {
    background: #0078BF;
}

.header-issue-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
}

@media (max-width: 1400px) {
    .name-user-msg {
        max-width: 200px;
    }
}

@media (max-width: 1399px) {
    .name-user-msg {
        max-width: 200px;
    }

    .message-block {
        padding: 60px 80px;
    }

    .name-user-chat {
        max-width: 130px;
    }

    .name-user-body-chat, .name-company-chat {
        max-width: 600px;
    }
}

@media (max-width: 1368px) {
    .msg-receiver, .msg-send {
        width: 80%;
        max-width: 80%;
    }
}

@media (max-width: 1199px) {
    .container-msg {
        max-width: 100% !important;
    }

    .name-user-chat-sub {
        max-width: 150px;
    }
}

@media (max-width: 1100px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 550px;
    }
}

@media (max-width: 1080px) {
    .name-user-msg {
        max-width: 150px;
    }
}

@media (max-width: 1050px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 500px;
    }
}

@media (max-width: 1000px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 400px;
    }

    .msg-receiver, .msg-send {
        width: 90%;
        max-width: 90%;
    }

    .name-user-msg {
        max-width: 110px;
    }
}

@media (max-width: 900px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 500px;
    }

    .name-user-msg {
        max-width: 200px;
    }

    .sidebar-chat {
        width: 0;
    }

    .chat-message-wrapper {
        grid-template-columns: 0 1fr;
    }

    .sidebar-chat.open {
        z-index: 9999;
        width: 300px;
    }

    .expand-menu-chat {
        display: block;
    }

    .name-user-chat {
        max-width: 160px;
    }

    .name-user-chat-sub {
        max-width: 170px;
    }
}

@media (max-width: 740px) {
    .name-user-msg {
        max-width: 130px;
    }

    .name-user-body-chat, .name-company-chat {
        max-width: 430px;
    }
}

@media (max-width: 660px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 380px;
    }

    .group-name-time {
        flex-direction: column;
        align-items: start;
    }
    .name-user-msg {
        font-size: 16px;
        max-width: 150px;
    }
}

@media (max-width: 600px) {
    .name-user-body-chat, .name-company-chat {
        max-width: 380px;
    }

    .group-name-time {
        flex-direction: unset;
        align-items: center;
    }

    .name-user-msg {
        font-size: 18px;
        max-width: 170px;
    }

    .message-block {
        padding: 40px;
    }
}

@media (max-width: 540px) {
    .name-user-body-chat {
        font-size: 20px;
        max-width: 350px;
    }

    .name-company-chat {
        max-width: 350px;
    }

    .group-name-time {
        flex-direction: column;
        align-items: start;
    }

    .name-user-msg {
        font-size: 18px;
        max-width: 180px;
    }

    .message-block {
        padding: 40px;
    }
}

@media (max-width: 500px) {
    .name-user-body-chat {
        font-size: 18px;
        max-width: 330px;
    }

    .name-company-chat {
        max-width: 330px;
    }

    .content-body-chat {
        padding: 0 25px 40px 25px;
    }

    .message-block {
        padding: 30px;
    }
}

@media (max-width: 470px) {
    .name-user-body-chat {
        font-size: 20px;
    }

    .content-body-chat {
        padding: 20px;
    }

    .name-user-body-chat {
        max-width: 280px;
    }

    .name-company-chat {
        max-width: 280px;
    }
}

@media (max-width: 430px) {
    .message-block {
        padding: 20px 10px;
    }

    .name-user-msg {
        max-width: 170px;
    }

    .name-user-body-chat {
        max-width: 250px;
    }

    .name-company-chat {
        max-width: 250px;
    }
}

@media (max-width: 400px) {
    .msg-receiver, .msg-send {
        padding: 10px;
    }

    .avatar-chat-content {
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    .group-avatar-status {
        margin-right: 10px;
    }

    .name-user-body-chat {
        max-width: 270px;
    }

    .name-company-chat {
        max-width: 270px;
    }
}

@media (max-width: 380px) {
    .name-user-body-chat {
        max-width: 250px;
        font-size: 16px;
    }

    .name-company-chat {
        max-width: 240px;
    }
}

@media (max-width: 349px) {
    .name-user-body-chat {
        font-size: 16px;
    }

    .name-user-body-chat {
        max-width: 240px;
    }

    .name-company-chat {
        max-width: 240px;
    }
}
