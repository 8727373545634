.not-found-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    border-radius: 15px;
    border: 1px solid #B3B3B3;
    padding: 20px;
    height: 270px;
    margin-top: 200px;
    background-color: #fff;
    min-width: 330px;
}

.text-not-found {
    font-family: "Noto Sans Bold";
    font-size: 24px;
}

.code-not-found {
    font-family: "Noto Sans Bold";
    font-size: 30px;
    margin-bottom: 20px;
    color: #0078BF;
}