.body-detail {
    display: flex;
    justify-content: start;
    align-items: center;
    background: #f1f2f6;
    width: 100%;
    padding: 71px 0;
}

.content-detail-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 85%;
    border-left: 2px solid #0078BF;
    padding-left: 27px;
    padding-top: 70px;
    padding-bottom: 40px;
}

.first-info-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap-reverse;
    margin-bottom: 60px;
}

.basic-info {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.avatar-info {
    width: 400px;
    height: 275px;
    background: #F4F4F4;
    border: 1px solid #B3B3B3;
    border-radius: 12px;
    margin-bottom: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.name-detail {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #2B2B2B;
    max-width: 600px;
    word-break: break-all;
}

.text-detail {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #555555;
    max-width: 530px;
    word-break: break-all;
}

.second-info-detail {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
}

.comment-block {
    width: 400px;
    height: 390px;
    background: #F4F4F4;
    border: 1px solid #0078BF;
    border-radius: 12px;
    padding: 30px 25px;
}

.comment-block-child {
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.detail-second {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 3px;
    color: #2B2B2B;
}

.btn-send-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 28px;
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
    position: absolute;
    bottom: -10px;
}

.list-comment-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.info-comment {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #757575;
    margin-right: 10px;
    width: 21px;
    cursor: pointer;
}

.evaluate-group {
    display: flex;
    flex-direction: column;
    align-items: start;
}


.user-evaluate {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #555555;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-evaluate {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #555555;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.detail-bottom {
    margin-bottom: 3rem;
}

@media (max-width: 1600px) {
    .avatar-info {
        width: 330px;
        height: 225px;
    }

    .comment-block {
        width: 330px;
        height: 327px;
    }

    .text-evaluate, .user-evaluate {
        width: 230px;
    }
}

@media (max-width: 1199px) {
    .text-detail {
        max-width: 400px;
    }
}

@media (max-width: 991px) {
    .first-info-detail {
        justify-content: flex-start;
    }
}

@media (max-width: 500px) {
    .body-detail {
        flex-direction: column-reverse;
        padding: 0 0 30px 0;
    }

    .content-detail-block {
        border-left: unset;
        padding-left: unset;
        border-bottom: 2px solid #0078BF;
        padding-bottom: 30px;
    }

    .avatar-info {
        width: 275px;
        height: 165px;
    }

    .comment-block {
        width: 275px;
        height: 270px;
    }

    .first-info-detail {
        margin-bottom: 20px;
    }

    .detail-bottom {
        margin-bottom: 20px;
    }

    .text-evaluate, .user-evaluate {
        width: 200px;
    }
}

@media (max-width: 349px) {
    .avatar-info {
        width: 240px;
        height: 165px;
    }

    .comment-block {
        width: 240px;
        height: 270px;
    }
}