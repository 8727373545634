.header-top-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 4px 3px -2px grey;
    height: 62px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 3px;
}

.header-navigation {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.btn-login-page {
    display: flex !important;
    justify-content: center;
    align-items: center;
    /*background: #FFFFFF !important;*/
    /*border: 1.5px solid #0078BF !important;*/
    background-color: #0078BF;
    border-radius: 19px !important;
    width: 182px !important;
    height: 38px;
}

.header-top-page .dropdown-menu, .header-mb .dropdown-menu {
    width: 182px;
    border: unset;
}

.header-top-page .text-type-login, .header-mb .text-type-login {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    color: #0078BF;
}

.header-top-page .text-type-login:hover, .header-mb .text-type-login:hover {
    color: #0078BF;
}

.header-top-page .text-type-login:active, .header-mb .text-type-login:active {
    color: #0078BF;
    background-color: #fff;
}

.btn-login-page .text-login {
    margin-right: 5px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
}

.btn-login-page .text-username {
    font-family: 'Noto Sans Bold';
    padding: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-header {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #555555;
}

.text-logo {
    width: 150px;
    font-family: '01 Digitall';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    color: #000000;
}

.header-mb {
    display: none;
}

@media (max-width: 640px) {
    .header-mb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        height: 50px;
    }

    .header-top-page {
        display: none;
    }

    .sidebar-mb {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 280px;
        border-right: 1px solid #bababa;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #fff;
        height: 100vh;
        padding: 0 10px;
    }

    .items-menu {
        padding-left: 20px;
        height: 40px;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .items-menu:hover {
        background-color: #bababa;
    }

    .sidebar-backdrop {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1030;
        width: 100vw;
        height: 100vh;
        background-color: #000015;
        transition: 0.3s;
        opacity: 0.5;
    }
}

@media (max-width: 740px) {
    .btn-login-page {
        width: 110px !important;
    }
}

@media (max-width: 640px) {
    .btn-login-page {
        width: 182px !important;
    }
}