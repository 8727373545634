.feature-block {
    width: 100%;
    height: 650px;
    background-color: #F1F2F6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-block {
    width: 100%;
    padding: 50px 127px;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #fff;
}

.title-part-top-page {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 10px;
}

.text-service {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0078BF;
}

.text-service-feature {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #555555;
}

.text-description-service {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 70px;
    color: #2B2B2B;
    margin-bottom: 50px;
}

.overview-service-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
}

.overview-service {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
    min-width: 140px;
    margin-bottom: 20px;
}

.overview-service img {
    width: 207px;
    border-radius: 50%;
    margin-bottom: 24px;
}

.des-service {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #2B2B2B;
    width: 140px;
}

.pick-up-block {
    background-color: #F1F2F6;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 50px 140px;
}

.list-pick-up {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.pick-up {
    width: 33%;
    height: 407px;
    min-width: 240px;
    border-radius: 10px;
    background-size: cover;
    display: flex;
    justify-content: center;
    margin: 0 10px 20px 10px;
    max-width: 318px;
    position: relative;
    background: #fff;
}

.group-pick-up {
    width: 100%;
}

.image-pick-up {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.image-pick-up .pick-up-default {
    width: 50%;
    height: 80%;
    object-fit: unset !important;
}

.header-pick-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-pick-up {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: absolute;
    bottom: 0;
    padding: 24px;
    width: 100%;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 13, 28, 0.7) 100%);
    min-height: 130px;
    border-radius: 0 0 10px 10px;
}

.tag-status {
    background: #FFFFFF;
    border: 1.5px solid #0078BF;
    border-radius: 19px;
    height: 30px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;
}

.tag-status div {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #0078BF;
}

.quantity-person {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    color: #0078BF;
    position: absolute;
    top: 20px;
    right: 20px;
}

.title-footer-pick-up {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.overview-footer-pick-up {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
}

.overview-footer-pick-up-title {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: #FFFFFF;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
}

@media (max-width: 1024px) {
    .pick-up {
        min-width: 220px;
    }

    .image-slide-block {
        width: 300px;
    }

    ul.indicators {
        width: 300px;
    }

    .description-slide {
        width: 300px;
    }
}

@media (max-width: 1600px) {
    .image-slide-block {
        width: 500px;
        min-width: 500px;
    }

    ul.indicators {
        width: 500px;
    }

    .pick-up-block {
    }
}

@media (max-width: 1368px) {
    .text-description-service {
        font-size: 30px;
    }

    .pick-up {
        min-width: 220px;
    }

    .pick-up-block {
    }
}

@media (max-width: 992px) {
    .service-block {
        padding: 50px;
    }

    .text-description-service {
        font-size: 26px;
    }

    .slide-title-description {
        font-size: 24px;
        line-height: 44px;
    }
}

@media (max-width: 920px) {
    .text-description-service {
        font-size: 26px;
    }
}

@media (max-width: 1024px) {
    .image-slide-block {
        width: 375px;
        min-width: 250px;
    }

    .description-slide {
        width: 375px;
    }

    ul.indicators {
        width: 375px;
    }

    .pick-up-block {
    }

    ul {
        padding-left: unset;
    }
}


@media (max-width: 750px) {
    .container {
        max-width: 100% !important;
        padding: unset !important;
    }

    .text-description-service {
        font-size: 24px;
        line-height: 44px;
    }

    .pick-up {
        min-width: 250px;;
    }
}

@media (max-width: 640px) {
    .text-service {
        font-size: 20px;
    }

    .text-service-feature {
        font-size: 12px;
    }

    .des-service {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .pick-up {
        width: 70%;
    }
}

@media (max-width: 400px) {
    .overview-service {
        min-width: 220px;
    }
}

@media (max-width: 1400px) {
    .pick-up-block {
        padding: 50px 100px;
    }

    .pick-up {
        width: 30%;
    }
}

@media (max-width: 1199px) {
    .pick-up-block {
        padding: 50px;
    }
}

@media (max-width: 991px) {
    .pick-up {
        width: 30%;
    }
}

@media (max-width: 900px) {
    .pick-up {
        width: 45%;
    }

    .service-block {
        padding: 50px 100px;
    }

    .pick-up-block {
        padding: 50px 100px;
    }
}

@media (max-width: 767px) {
    .pick-up {
        width: 80%;
    }

    .list-pick-up {
        justify-content: center;
    }
}

@media (max-width: 750px) {
    .pick-up-block {
        padding: 50px 100px;
    }
}

@media (max-width: 500px) {
    .pick-up-block {
        padding: 50px;
    }

    .service-block {
        padding: 50px;
    }

    .title-part-top-page {
        padding-left: 0;
    }
}

@media (max-width: 370px) {
    .pick-up-block {
        padding: 50px 20px;
    }
}