@font-face {
    font-family: "01 Digitall";
    src: local("01 Digitall"), url("../src/fonts/01digitall/01-Digitall.ttf") format("truetype");
}

@font-face {
    font-family: 'Noto Sans Regular';
    src: local("Noto Sans Regular"), url("../src/fonts/Noto_Sans_JP/NotoSansJP-Regular.otf") format("truetype");
}

@font-face {
    font-family: 'Noto Sans Regular';
    src: local("Noto Sans Regular"), url("../src/fonts/Noto_Sans_JP/NotoSansJP-Regular.otf") format("truetype");
}

@font-face {
    font-family: 'Noto Sans Bold';
    src: local("Noto Sans Bold"), url("../src/fonts/Noto_Sans_JP/NotoSansJP-Bold.otf") format("truetype");
}

@font-face {
    font-family: 'Noto Sans Medium';
    src: local("Noto Sans Medium"), url("../src/fonts/Noto_Sans_JP/NotoSansJP-Medium.otf") format("truetype");
}

@font-face {
    font-family: 'Epilogue';
    src: local("Epilogue"), url("../src/fonts/Epilogue/Epilogue-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter Bold';
    src: local("Inter"), url("../src/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

#root {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    overflow: auto;
    min-height: 100vh;
}

.logo-page {
    font-family: '01 Digitall';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    color: #000000;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    background: #6c757d;
}

.full-viewport {
    overflow: unset !important;
}

.input-system {
    background: #FFFFFF;
    border: 1px solid #B3B3B3;
    border-radius: 8px;
    height: 38px;
    padding: 0 15px;
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.input-system::placeholder {
    color: #B3B3B3;
    font-family: 'Noto Sans Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.color-000000 {
    color: #000000;
}

.color-D32929 {
    color: #D32929;
}

input:focus {
    outline: none;
}

.main-block {
    display: flex;
    justify-content: center;
}

.container-app {
    width: 75%;
    overflow-x: hidden;
}

.button-primary {
    height: 38px;
    background: #0078BF;
    border-radius: 19px;
    min-width: 112px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
}

.button-secondary {
    height: 38px;
    background: #DEDFE1;
    border-radius: 19px;
    min-width: 112px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #0078BF;
}

.main-text {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    word-break: break-word;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

ul {
    padding-left: unset !important;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.w-25 {
    width: 25%;
}

.text-error {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #D32929;
    word-break: break-all;
}

.container .invalid-feedback {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #D32929;
    margin-top: 10px;
    word-break: break-all;
}

.border-error {
    border: 1px solid #D32929;
}

.title-form {
    font-family: 'Noto Sans Bold';
    font-size: 20px;
    color: #0078BF;
    margin-right: 20px;
}

.title-form-sub {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    color: #555555;
}

.text-line-area {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #555555;
    padding: 0 10px;
    background: #fff;
    z-index: 10;
}

.group-line-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
}

.line-area {
    width: 100%;
    border-top: 1px solid #555555;
    position: absolute;
    top: 50%;
}

.text-link {
    font-family: 'Noto Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #0078BF;
    text-decoration: none;
}

.text-link:hover {
    color: #0078BF;
}

a {
    text-decoration: none !important;
}

.custom-checkbox input[type="checkbox"] {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.custom-checkbox label {
    position: relative;
    cursor: pointer;
    font-family: "Be Vietnam Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5a5a5a;
    margin-top: 2px;
    padding-left: 32px;
}

.custom-checkbox label:before {
    content: "";
    -webkit-appearance: none;
    padding: 10px;
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    top: -7px;
    left: 0px;
}

.custom-checkbox input:checked + label:before {
    background: #0078BF;
    border: 1px solid #0e65b0;
}

.custom-checkbox input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: -3px;
    left: 8px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox {
    margin-right: 30px;
}

.checkbox-system {
    width: 24px;
    height: 24px;
}

.font-12 {
    font-size: 12px !important;
}

.text-link-highlight:hover {
    text-decoration: underline !important;
}

.text-three-dots {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.no-data {
    font-family: 'Noto Sans Regular';
    font-size: 16px;
    color: #D32929;
    width: 100%;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

select {
    appearance: none;
    background-image: url("/src/assets/image/arrow_down.svg") !important;
    background-repeat: no-repeat !important;
    background-position: right 1rem center !important;
    padding-right: 30px !important;
}

@media (max-width: 991px) {
    .container-app {
        min-width: 100% !important;
    }
}

.text-break {
    word-break: break-all;
}

.border-unset {
    border: unset !important;
}

.btn-update-mail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-family: "Noto Sans Bold";
    font-size: 14px;
    color: #fff;
    background: #0078BF;
    height: 38px;
    width: 150px !important;
    border-radius: 19px;
}

.group-email {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 70%;
    margin-bottom: 20px;
    margin-right: 20px;
}

@media (max-width: 1100px) {
    .group-email {
        width: 100%;
    }
}

.btn-system {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    font-family: "Noto Sans Bold";
    font-size: 14px;
    color: #fff;
    background: #0078BF;
    min-height: 38px;
    border-radius: 19px;
    min-width: 112px;
}