.group-register {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    min-height: 130px;
}

.form-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    min-width: 500px;
    max-width: 500px;
    border-radius: 15px;
    border: 1px solid #B3B3B3;
    padding: 20px;
    height: 270px;
    margin-top: 200px;
    background-color: #fff;
    height: fit-content;
}

.form-register-password {
    height: 380px;
}

.group-register-password {
    min-height: 230px;
}

.form-forget-password {
    height: 340px;
}

.group-forget-password {
    min-height: 200px;
}

.group-reset-password {
    min-height: 290px;
}

.form-reset-password {
    height: 430px;
}

@media (max-width: 600px) {
    .form-register {
        max-width: 450px;
        width: 80%;
        min-width: 320px!important;
    }
}

@media (max-width: 450px) {
    .form-register {
        min-width: 340px;
    }

    .form-register-password {
        height: 395px;
    }

    .form-forget-password {
        height: 360px;
    }
}

.form-complete {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
}

.form-complete .title-form {
    margin-bottom: unset;
}

@media (max-height: 800px) {
    .form-register {
        margin-top: 100px;
    }
}