.top-content-block {
    background: #F1F2F6;
    height: 218px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 35px 120px;
}

.title-top-content {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #2B2B2B;
    margin-bottom: 20px;
}

.switch-page-group {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.btn-switch-page {
    width: 210px;
    height: 38px;
    background: #0078BF;
    box-shadow: 0 -2px 50px rgba(0, 0, 0, 0.1);
    border-radius: 19px;
    color: #FFFFFF;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    margin-bottom: 10px;
}

.btn-switch-page.active {
    background: #fff;
    color: #0078BF;
}

.title-result {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #0078BF;
}

.form-search-condition {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.form-search-condition .select-company {
    margin: unset;
    min-width: 200px;
}

.group-select {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 20px;
    margin-top: 20px;
}

@media (max-width: 1399px) {
    .top-content-block {
        padding: 35px 100px;
    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .top-content-block {
        padding: 35px 40px;
    }
}

@media (max-width: 992px) {
    .top-content-block {
        padding: 35px 50px;
    }
}

@media (max-width: 650px) {
    .title-top-content {
        font-size: 35px;
    }

    .top-content-block {
        align-items: center;
    }

    .switch-page-group {
        justify-content: center;
    }

    .btn-switch-page {
        margin-right: unset;
    }
}

@media (max-width: 375px) {
    .title-top-content {
        font-size: 26px;
    }
}