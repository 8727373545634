.group-btn-feature {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px;
}

.content-my-page {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.btn-feature-custom {
    margin-right: unset;
    margin-bottom: unset;
}

.btn-feature-custom:first-child {
    margin-right: 50px;
}

.result-search-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 50px 120px;
}

.course-list {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0078BF;
    margin-right: 20px;
}

.course-list-sub {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 3px;
    color: #555555;
}

.list-course {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 318px));
    width: 100%;
    grid-column-gap: 50px;
    padding: 50px 0;
}

.course-items {
    height: 407px;
    border-radius: 14px;
    min-height: 407px;
    border: 3px solid #0078BF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
}

.course-items.border-unset {
    justify-content: center;
    position: relative;
}

.course-items.border-unset .header-course {
    position: absolute;
    top: 20px
}

.avatar-issue {
    width: 95%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: calc(100% - 140px);
    object-fit: cover;
}

.avatar-issue.avatar-issue-default-list {
    height: 80%;
    background-size: 50%;
    object-fit: cover;
}

.info-course {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #F1F2F6;
    border-radius: 0 0 14px 14px;
    padding: 20px;
    height: 140px;
    max-height: 140px;
    min-height: 140px;
    width: 100%;
    justify-content: space-between;
}

.items-issue {
    position: relative;
}

.info-course.info-course-custom {
    background: linear-gradient(rgba(217, 217, 217, 0), rgba(0, 13, 28, 0.7));
    position: absolute;
    bottom: 0;
}

.info-course.issue-pay-fee {
    background: #0078BF;
    border-radius: 0 0 8px 8px;
}

.issue-pay-fee .info-detail, .issue-pay-fee .title-course, .issue-pay-fee .title-course-custom {
    color: #fff;
}

.info-detail {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 32px;
    color: #2B2B2B;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 250px;
}

.title-course {
    display: -webkit-box;
    font-style: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    color: #2B2B2B;
    font-family: "Noto Sans Bold";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.btn-detail-issue {
    border-radius: 19px;
    background: #0078BF;
    box-shadow: 0 -2px 50px 0 rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    color: #FFF;
    font-family: "Noto Sans Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.title-course-custom {
    display: -webkit-box;
    font-weight: 600;
    line-height: 32px;
    color: #2B2B2B;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    font-family: "Noto Sans Bold";
    font-size: 20px;
    font-style: normal;
}

.issue-pay-fee .btn-detail-issue {
    background: #fff;
    color: #0078BF;
}

.header-course {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    top: 20px;
    padding: 0 20px;
}

.type-issue-tag {
    font-family: 'Noto Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: #0078BF;
    height: 32px;
    left: 142px;
    top: 23px;
    background: #FFFFFF;
    border: 1.5px solid #0078BF;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.btn-fee-issue {
    min-width: 70px;
}

.quantity-person-issue {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #0078BF;
}

.favorite-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
}

.btn-feature-my-page {
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.pick-up-default {
    object-fit: unset;
    width: 50%;
    height: 70%;
}

.pointer-event-none {
    pointer-events: none;
}

@media (max-width: 1399px) {
    .list-course {
        grid-template-columns: repeat(auto-fit, 317px);
        grid-column-gap: 60px;
        padding: 50px 100px;
    }

    .result-search-block {
        padding: 50px 100px;
    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .condition-search {
        padding: 20px 40px;
    }

    .result-search-block {
        padding: 50px 40px;
    }

    .list-course {
        padding: 50px 20px;
        grid-column-gap: 15px
    }
}

@media (max-width: 1199px) {
    .list-course {
        grid-template-columns: repeat(auto-fit, 317px);
        grid-column-gap: 20px;
        padding: 50px 40px;
    }

    .condition-search {
        flex-direction: column;
        align-items: start;
    }

    .title-search, .select-search, .input-search-keyword {
        margin-bottom: 20px;
    }
}

@media (max-width: 972px) {
    .list-course {
        grid-template-columns: repeat(auto-fit, 317px);
        grid-column-gap: 50px;
        padding: 50px 0;
    }
}

@media (max-width: 922px) {
    .list-course {
        grid-template-columns: repeat(auto-fit, 317px);
        grid-column-gap: 25px;
        padding: 50px 0;
    }

    .result-search-block {
        padding: 50px;
    }

    .condition-search {
        padding: 20px 50px;
    }
}

@media (max-width: 800px) {
    .list-course {
        grid-template-columns: repeat(auto-fit, 300px);
        grid-column-gap: 30px;
        padding: 50px 0;
    }
}

@media (max-width: 746px) {
    .list-course {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .course-items {
        width: 317px;
    }
}

@media (max-width: 728px) {
    .group-btn-feature {
        padding: 50px 150px;
    }
    .btn-feature-custom:first-child {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 546px) {
    .group-btn-feature {
        padding: 50px;
    }
}

@media (max-width: 420px) {
    .result-search-block {
        padding: 50px 20px;
    }

    .condition-search {
        padding: 20px 20px;
    }
}

@media (max-width: 375px) {
    .info-detail {
        width: 230px;;
    }
}